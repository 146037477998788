<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">SERVICE</div>
    <!-- <div class="header">SUPPORT & SERVICE</div> -->
    <div class="title">
      <div class="title_title">{{ title }}</div>
      <p
        v-for="(item, index) in contentList"
        :key="index"
        v-html="item.content"
      ></p>
    </div>
    <!-- 下载部分 -->
    <div class="download">
      <div
        class="download_box"
        v-for="(item, index) in downloadList"
        :key="index"
        @click="download(item.fileUrl)"
      >
        <img src="@/assets/index/download.png" alt="" />
        <div>{{ item.fileName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
export default {
  mounted() {
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "/support", title: "SUPPORT & SERVICE" },
      { path: "", title: "SERVICE" },
    ];
    this.supportServiceInfo();
  },
  data() {
    return {
      breadcrumbList: [],
      downloadList: [
      
      ],
      contentList: [], //内容数组
      title: "", //标题
    };
  },
  components: {
    Breadcrumb,
  },
  methods: {
    //获取支持服务-服务
    supportServiceInfo() {
      api.supportServiceInfo().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.downloadList = data.data.fileList;
          this.contentList = data.data.contentList;
          this.title = data.data.title;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //下载
    download(url) {
      //下载请求
      console.log(url);
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/index/carousel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 186px;
  text-align: center;
}

.title {
  .title_title {
    font-size: 36px;
    font-family: Arial;
    font-weight: normal;
    color: #004a9c;
    text-align: center;
    margin-bottom: 57px;
  }
  margin: 50px auto 57px;
  width: 1136px;
  line-height: 24px;
  font-size: 16px;
  font-family: Arial;
  font-weight: normal;
  color: #5a5b5e;
}
.download {
  width: 1156px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 80px;
  flex-wrap: wrap;
  .download_box {
    width: 316px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
    cursor: pointer;
    div {
      width: 260px;
      text-align: center;

      font-size: 16px;
      font-family: Arial;
      font-weight: 400;
      color: #004a9c;
    }
    img {
      height: 20px;
      width: 21px;
    }
  }
  .download_box:not(:nth-of-type(3n)) {
    margin-right: 73px;
  }
  .download_box:hover {
    outline: 1px solid #004a9c;
  }
}
</style>